export const environment = {
  production: false,
//  API: 'https://api.staging.yimify.io/v1',
  API: 'https://api.bistduanders.de/v1',
  websocket: 'wss://api.bistduanders.de:3000',
  bugsnagAPIKey: '9a30a2a3c8eba4288183a56bb74a1735',
  Default_language: 'de',
  searchInfluencerItemsPerRequest: 15,
  stripeKey: 'pk_test_4KdXcNvqill4Cgc4nml5TQgR',
  BLOG: 'https://www.yimify.com/wp-json/wp/v2/posts',
  CAMPAIGN_DETAIL_MEDIA_SLIDER_INTERVAL: 2000,
  advertiserEndpoint: 'customer',
//  FILE_PREFIX_URL: 'https://s3.eu-central-1.amazonaws.com/data.staging.yimify.io'
  FILE_PREFIX_URL: 'https://api.bistduanders.de/files'
};
