import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ServerModule } from '@yim/server';
import { UiModule } from '@yim/ui';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, CollapseModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxGalleryModule } from 'ngx-gallery';
import { OwlModule } from 'ngx-owl-carousel';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';

import { DropZoneComponent, VideoPopupComponent } from './components';
import { YimifyDropdownDirective } from './directive';

/*
  Shared Module is used to combine all common module in one module and
  used it anywhere in application whenever required
*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxGalleryModule,
    TabsModule,
    PerfectScrollbarModule,
    CollapseModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forChild(),
    NgxGalleryModule,
    OwlModule,
    UiModule,
    ServerModule
  ],
  declarations: [
    VideoPopupComponent,
    DropZoneComponent,
    YimifyDropdownDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VideoPopupComponent,
    TabsModule,
    PerfectScrollbarModule,
    CollapseModule,
    BsDropdownModule,
    AccordionModule,
    BsDatepickerModule,
    NgxGalleryModule,
    DropZoneComponent,
    OwlModule,
    YimifyDropdownDirective,
    UiModule,
    ServerModule
  ],
  providers: [
    DatePipe,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
  ]
})
export class SharedCustomModule {

}
